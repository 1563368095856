import React, { useState } from "react";
import { createConfessionRequest } from "../../../services/confessions/confession.service";
import Recaptcha from "react-recaptcha";
import PulseLoader from "react-spinners/PulseLoader";

export const ConfessionForm = ({ setParentState }) => {
  const [confession, setConfession] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [isHuman, setIsHuman] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const validateForm = () => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (confession.length && regex.test(email)) {
      return true;
    } else {
      if (!regex.test(email)) {
        setError("Please enter a valid email address.");
      }
      if (!confession.length) {
        setError("Please enter a confession.");
      }
      return false;
    }
  };

  const verifyCallback = (recaptchaToken) => {
    if (recaptchaToken) {
      setIsHuman(true);
    }
  };

  return (
    <div id="confessionFormContainer">
      <form id="confessionForm">
        <h2>Bless me, Father, for I have sinned:</h2>
        <br />
        <textarea
          id="confession"
          name="confession"
          placeholder="Enter your confession here..."
          value={confession}
          onChange={(event) => {
            setConfession(event.target.value);
          }}
        />
        <label htmlFor="email">Email address: *</label>
        <br />
        <input
          type="email"
          name="email"
          id="email"
          placeholder="someone@example.com"
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        />
        <br />
        <p style={{ textStyle: "italic", color: "red" }}>{error}</p>
        <Recaptcha
          sitekey="6LcYsX8qAAAAAFhGNDNSJtDA6gGQZggBb5dQfIDq"
          style={{ border: "2px solid black", height: "40px", width: "100%" }}
          verifyCallback={verifyCallback}
        />
        <div id="submitBtnContainer">
          {!isSending ? (
            <button
              id="submitConfession"
              onClick={(event) => {
                event.preventDefault();
                if (validateForm()) {
                  if (isHuman) {
                  // if (true) {
                    setIsSending(true);
                    createConfessionRequest(email, confession)
                      .then(() => {
                        setIsSending(false);
                        setParentState(2);
                      })
                      .catch((e) => {
                        setIsSending(false);
                        setError("There was a problem sending the confession.");
                      });
                  } else {
                    setError("Please indicate that you are not a bot.");
                  }
                }
              }}
            > 
              SUBMIT MY CONFESSION
            </button>
          ) : (
            <PulseLoader
              color={"#295927"}
              loading={isSending}
              size={20}
              speedMultiplier={0.5}
            />
          )}
        </div>
      </form>
    </div>
  );
};
